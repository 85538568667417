import React, {useState, useEffect} from 'react';
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';
import { 
    ButtonStyled, 
    ButtonWrapper, 
    MyMentorWidget, 
    Subtitle, 
    Mentor, 
    TextWrapper, 
    Title 
} from "./styles";


async function getMentor(token, mentor) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/matching/mymentor", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return null;
    return json.data;
}

function MyMentor({token}) {
    const [mentor, setMentor] = useState(null);

    useEffect(() => {
        (async () => {
            let res = await getMentor(token);
            if (res != null) setMentor(res);
        })();
    }, []);

    return (
        <MyMentorWidget>
                <TextWrapper>
                    <Title>Current Mentorship</Title>
                    <Subtitle>{"Mentoring topic: " + (mentor ? mentor.topicname : "")}</Subtitle>
                    <Mentor href="">{"Mentor: " + (mentor ? mentor.firstname + " " + mentor.secondname : "")}</Mentor>
                </TextWrapper>
                <ButtonWrapper>
                    <SharedButton buttonType="Action" width="Long">Book a meeting</SharedButton>
                </ButtonWrapper>
        </MyMentorWidget>
    )
}

export default MyMentor;