import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { baseURL } from '../../../config/config';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Avatar from '@mui/material/Avatar';
import SharedButton from '../SharedButton/SharedButton';

// Call API to get user's name and image
async function getUserInfo(params) {
  const auth_header = "Bearer " + params.loginToken;
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
  };

  var response = await fetch(baseURL + "/profile", request)
  return response.json();
}

// Make sure an image URL is valid
function isValidImage(url) {
  console.log(url);
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

const SharedDrawerMentor = ({ token }) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
      getData();
  }, []);

  var getData = async () => {
    const res = await getUserInfo({ loginToken: token });
    if (res.responseCode === 200) {
      setName(res.data.firstName + " " + res.data.secondName);
      setImage(res.data.profilePictureURL);// if invalid, default profile picture will be used
    }
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >   
        <List>
            <ListItem button key='Name'>
                <ListItemIcon><Avatar alt={name} src={image} /></ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button key='View Profile'>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary='View Profile' />
            </ListItem>
            <ListItem button key='Log out'>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary='Log out' />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button component={Link} to="/mentor/home" key='Dashboard'>
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary='Dashboard' />
            </ListItem>
            <ListItem button key='My Mentees'>
                <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                <ListItemText primary='My Mentees' />
            </ListItem>
            <ListItem button key='My Meetings'>
                <ListItemIcon><MeetingRoomIcon /></ListItemIcon>
                <ListItemText primary='My Meetings' />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button key='Settings'>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary='Settings' />
            </ListItem>
            <ListItem button key='Provide Feedback'>
                <ListItemIcon><RateReviewIcon /></ListItemIcon>
                <ListItemText primary='Provide Feedback' />
            </ListItem>
        </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SharedButton buttonType="Menu" onClick={toggleDrawer('left', true)}>{'≡'}</SharedButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default SharedDrawerMentor;