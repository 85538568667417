import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import SharedButton from "../../shared/SharedButton/SharedButton";
import {
    BasicInformationContainer,
    Input,
    InputContainer,
    Label,
    NameContainer,
    NameWrapper,
    ProfileInformationContainer,
    RegistrationContainer,
    TitleContainer,
    CharacterCount,
    Title
} from "./styles";

function BasicInformation(props) {
    const [count, setCount] = useState(0);

    const {
        handleChange,
        handleProfileDescChange,
        values,
        errors
    } = props;

    const {
        firstName,
        lastName,
        businessArea,
        jobTitle,
        profilePhoto,
        profileDescription
    } = values;

    const {
        firstNameError,
        lastNameError,
        businessAreaError,
        jobTitleError,
    } = errors;

    const handleProfileChange = (e) => {
        setCount(e.target.value.length);
        handleProfileDescChange(e.target.value);
    }

    return (
        <RegistrationContainer>
            <BasicInformationContainer>
                <TitleContainer>
                    <Title>Basic Information</Title>
                </TitleContainer>
                <NameContainer container spacing={2}>
                    <NameWrapper item xs={6}>
                        <Label>First Name</Label>
                        <TextField
                            required
                            error={firstNameError}
                            id="firstName"
                            label="First Name"
                            value={firstName}
                            onChange={handleChange("firstName")}
                            helperText={firstNameError ? "Please enter your first name." : ""}
                            autoFocus
                            fullWidth
                        />
                    </NameWrapper>
                    <NameWrapper item xs={6}>
                        <Label>Last Name</Label>
                        <TextField
                            required
                            error={lastNameError}
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={handleChange("lastName")}
                            helperText={lastNameError ? "Please enter your last name." : ""}
                            fullWidth
                        />
                    </NameWrapper>
                </NameContainer>
                <InputContainer>
                    <Label>Business Area</Label>
                    <TextField
                        label="Business Area"
                        id="business-area"
                        error={businessAreaError}
                        value={businessArea}
                        onChange={handleChange("businessArea")}
                        helperText={businessAreaError ? "Please choose your business area." : ""}
                        fullWidth
                        required
                        select
                    >
                        <MenuItem value="Marketing">Marketing</MenuItem>
                        <MenuItem value="Finance">Finance</MenuItem>
                        <MenuItem value="Operations Management">Operations Management</MenuItem>
                        <MenuItem value="Human Resource">Human Resource</MenuItem>
                        <MenuItem value="IT">IT</MenuItem>
                    </TextField>
                </InputContainer>
                <InputContainer>
                    <Label>Job Title</Label>
                    <TextField
                        required
                        error={jobTitleError}
                        id="jobTitle"
                        label="Job Title"
                        value={jobTitle}
                        onChange={handleChange("jobTitle")}
                        helperText={jobTitleError ? "Please enter your job title." : ""}
                        fullWidth
                    />
                </InputContainer>
            </BasicInformationContainer>
            <ProfileInformationContainer>
                <TitleContainer>
                    <Title>Profile Information</Title>
                </TitleContainer>
                <InputContainer>
                    <Label>Profile Photo</Label>
                    <label htmlFor="profile-upload">
                        <Input id="profile-upload" accept="image/*" type="file" />
                        <SharedButton buttonType="Action" width="Long" component="span">Upload Photo</SharedButton>
                    </label>
                </InputContainer>
                <InputContainer>
                    <Label>Profile Description</Label>
                    <TextField
                        required
                        multiline
                        rows={4}
                        value={profileDescription}
                        label={"Profile Description"}
                        inputProps={{ maxLength: 150 }}
                        onChange={handleProfileChange}
                        fullWidth
                    />
                    <CharacterCount>{count}/150 characters</CharacterCount>
                </InputContainer>
            </ProfileInformationContainer>
        </RegistrationContainer>
    )
}

export default BasicInformation;