import TextField from '@mui/material/TextField';
import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { FontSize } from '../../assets/font';
import { useState } from 'react';
import { baseURL } from '../../config/config';
import { NativeSelect } from '@mui/material';
import { Input } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopTimePicker from '@mui/lab/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from "react-select";
import Rating from '@mui/material/Rating';
import {
	FieldWrapper,
	FormLabel,
	HeaderOne,
	HeaderTwo,
	HeaderThree,
	CreateMeetingWrapper,
	SelectWrapper
} from "./styles";

/* NEED TO ADD: -JSON REQUEST TO GET LIST OF TOPICS TO FILL THE TOPIC DROP DOWN
				-STORE MENTORID TO INCLUDE IN JSON POST
*/


const defaultValues = {
	firstname: "",
	lastname: "",
	businessarea: "",
	jobtitle: "",
	profilephoto: "",
	profiledescription: "",
	learningstyle: "",
	selectedproficiency: ""
};

function EditMenteeProfile() {
	const businessAreaOptions = [
		{
			areaid: 0,
			areaname: "area 0"
		},
		{
			areaid: 1,
			areaname: "area 1"
		},
		{
			areaid: 2,
			areaname: "area 2"
		},
		{
			areaid: 3,
			areaname: "area 3"
		}
	];

	// const proficiencyOptions = [
	// 	{
	// 		proficiencyid: 0,
	// 		proficiencyname: "proficiency 0"
	// 	},
	// 	{
	// 		proficiencyid: 1,
	// 		proficiencyname: "proficiency 1"
	// 	},
	// 	{
	// 		proficiencyid: 2,
	// 		proficiencyname: "proficiency 2"
	// 	}
	// ];

	const learningStyleOptions = [
		{
			styleid: 0,
			stylename: "style 0"
		},
		{
			styleid: 1,
			stylename: "style 1"
		},
		{
			styleid: 2,
			stylename: "style 2"
		}
	];
	
	const proficiencies = ["proficiency 1", "proficiency 2", "proficiency 3"];

	// add in extra proficiencies for rating
	const theseValues = {
		...defaultValues
	};
	proficiencies.forEach(proficiency => {
		theseValues[proficiency] = 2;
	});

	const [values, setValues] = useState(defaultValues);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setValues({...values, [name]: value});
	};

	const handleStarChange = (name) => (e, value) => {
		setValues({...values, [name]: value});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(values);
	}

	// const [topic, setTopicValue] = React.useState();
	// const [date, setDateValue] = React.useState(new Date());
	// const [duration, setDurationValue] = React.useState(10);
	// const [description, setDescriptionValue] = React.useState();

	// const [locationError, setLocationError] = useState(false);
	// const [dateTimeError, setDateTimeError] = useState(false);
	// const [locationHelperText, setLocationErrorText] = useState("");
	// const [dateTimeHelperText, setDateTimeHelperText] = useState(false);

	// const { location, description } = values;

	return (
		<>
			<CreateMeetingWrapper container>
				<Grid>
					<Grid>
						<HeaderOne>Edit mentee profile</HeaderOne>
					</Grid>
					<Grid>
						<Grid>

							<FieldWrapper>				
								<FormLabel>First Name</FormLabel>
								<TextField 
									id="firstname"
									name="firstname"
									label="First Name"
									type="text"
									value={values.firstname}
									onChange={handleInputChange}
									fullWidth
									required
								/>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Last Name</FormLabel>
								<TextField 
									id="lastname"
									name="lastname"
									label="Last Name"
									type="text"
									value={values.lastname}
									onChange={handleInputChange}
									fullWidth
									required
								/>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Business Area</FormLabel>
								<TextField 
									id="businessarea"
									name="businessarea"
									label="Business Area"
									type="text"
									value={values.businessarea}
									onChange={handleInputChange}
									SelectProps={{
										native: true,
									}}							
									fullWidth
									required
									select
								>
									<option value="" />
									{businessAreaOptions.map(option => (
										<option key={option.areaid} value={option.areaid}>
											{option.areaname}
										</option>
									))}
								</TextField>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Job Title</FormLabel>
								<TextField 
									id="jobtitle"
									name="jobtitle"
									label="Job Title"
									type="text"
									value={values.jobtitle}
									onChange={handleInputChange}
									fullWidth
									required
								/>
							</FieldWrapper>

							<HeaderTwo>More Profile Information</HeaderTwo>
							<FormLabel>This information helps us find a perfect match for your mentor!</FormLabel>

							<FieldWrapper>				
								<FormLabel>Profile Description</FormLabel>
								<TextField 
									id="profiledescription"
									name="profiledescription"
									label="Profile Description"
									type="text"
									value={values.profiledescription}
									onChange={handleInputChange}
									fullWidth
									required
									multiline
									rows="5"
									helperText="Make sure to tell us about yourself, your hobbies and your goals!"
								/>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Learning Style</FormLabel>
								<TextField 
									id="learningstyle"
									name="learningstyle"
									label="Learning Style"
									type="text"
									value={values.learningstyle}
									onChange={handleInputChange}
									SelectProps={{
										native: true,
									}}							
									fullWidth
									required
									select
								>
									<option value="" />
									{learningStyleOptions.map(option => (
										<option key={option.styleid} value={option.styleid}>
											{option.stylename}
										</option>
									))}
								</TextField>
							</FieldWrapper>


							<FormLabel>How good are you at the moment at each of the following skills?</FormLabel>
								
							{proficiencies.map(p => (
								<FieldWrapper>
									<FormLabel>{p}</FormLabel>
									<Rating
										name={p}
										value={values[p]}
										// onChange={(event, newValue) => {
										// 	console.log("here");
										// 	handleStarChange(event, newValue, p);
										// }}
										onChange={handleStarChange(p)}
									/>
								</FieldWrapper>
							))}

							{/* Material UI has loadingButton can add later for better UX */}
							<Button
								onClick={handleSubmit}
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Save
							</Button>

						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</>
	)
}

export default EditMenteeProfile;