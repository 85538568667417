import styled, { css } from 'styled-components';
import { Button } from "@mui/material";
import Color from '../../../assets/colors';
import { FontSize } from '../../../assets/font';

export const ButtonStyled = styled(Button)`
    && {
        ${(props) =>
        props.buttonType == "Action" &&
        css`
            width: ${props.width == "Long" ? "270px" : "148px"};
            height: ${props.width == "Long" ? "46px" : "50px"};
            padding: 10px 20px;
            background-color: ${Color.blue100};
            color: ${Color.white};
            text-transform: uppercase;
            font-size: ${FontSize.body};
            font-weight: 500;

            &:hover {
            cursor: pointer;
            background: ${Color.blue80}
            }
        `
    }

        ${(props) =>
        props.buttonType == "Error" &&
        css`
            width: ${props.width == "Long" ? "270px" : "148px"};
            height: ${props.width == "Long" ? "46px" : "50px"};
            padding: 10px 20px;
            border-style: solid;
            border-width: 3px;
            border-color: ${Color.red100};
            color: ${Color.red100};
            text-transform: uppercase;
            font-size: ${FontSize.body};
            font-weight: 500;

            &:hover {
            cursor: pointer;
            background: ${Color.red80};
            color: ${Color.white};
            }
        `
    }

        ${(props) =>
        props.buttonType == "Secondary" &&
        css`
            width: ${props.width == "Long" ? "270px" : "148px"};
            height: 40px;
            padding: 10px 20px;
            background-color: ${Color.grey100};
            color: ${Color.white};
            text-transform: uppercase;
            font-size: ${FontSize.body};
            font-weight: 500;

            &:hover {
            cursor: pointer;
            background: ${Color.grey80}
            }
        `
    }

    ${(props) =>
        props.buttonType == "Menu" &&
        css`
            width: ${props.width == "Long" ? "270px" : "40px"};
            height: ${props.width == "Long" ? "46px" : "55px"};
            padding: 10px 10px;
            background-color: ${Color.white};
            border: "3px solid " + ${Color.black};
            color: ${Color.black};
            text-transform: uppercase;
            font-size: ${FontSize.header3};
            font-weight: 500;

            &:hover {
            cursor: pointer;
            color: ${Color.white};
            background: ${Color.blue100}
            }
        `
    }

        ${(props) =>
        props.disabled &&
        css`
            opacity: 0.9;
            cursor: not-allowed;
        `
        }
    }
    }
`;