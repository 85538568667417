import TextField from '@mui/material/TextField';
import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { baseURL } from '../../config/config';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopTimePicker from '@mui/lab/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
//import SharedSidebar from '../shared/SharedSidebar/SharedSidebar'

import {
	FieldWrapper,
	FormLabel,
	HeaderOne,
	CreateMeetingWrapper,
	SelectWrapper
} from "./styles";

/* NEED TO ADD: -JSON REQUEST TO GET LIST OF TOPICS TO FILL THE TOPIC DROP DOWN
				-STORE MENTORID TO INCLUDE IN JSON POST
*/

async function createMeeting(params) {
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(params)
	};

	return fetch(baseURL + "/event/create", request)
		.then(res => res.json())
}


function Createmeeting() {

	/*const requireAuth = (...) => {
		if(!localStorage.getItem('token')) {
			// go to login route
		}

	}*/

	const [values, setValues] = useState({
		topic: "",
		//date: "",
		duration: "",
		location: "",
		description: ""
	});

	const [errors, setErrors] = useState({
		topicError: false,
		dateError: false,
		durationError: false,
		locationError: false,
	});

	const {
		topic,
		//date,
		duration,
		location,
		description
    } = values;

	const {
        topicError,
        dateError,
        durationError,
        locationError
    } = errors;

	const dateTime = new Date();
	const [date, setDateValue] = React.useState(new Date());

	const handleChange = (name) => (e) => {
		let cValues = { ...values };
        let cErrors = { ...errors }

		if (name == "topic" && errors["topicError"]) {
            cErrors["topicError"] = false;
        }
		if (name == "date" && errors["dateError"]) {
            cErrors["dateError"] = false;
        }
		if (name == "duration" && errors["durationError"]) {
            cErrors["durationError"] = false;
        }
        if (name == "location" && errors["locationError"]) {
            cErrors["locationError"] = false;
        }

        cValues[name] = e.target.value;
        setValues(cValues);
        setErrors(cErrors);
	};
	
	const handleCreate = (e) => {
		if (!validateMeetingInfo()) {
			return;
		}

		let params = {
			topic: topic,
			date: date,
			duration: duration,
			type: "1-1",
			location: location,	
			description: description
		}

		console.log(JSON.stringify(params));

		const token = createMeeting(params);
		//setToken(token.data);

	}

	const validateMeetingInfo = () => {
		let cErrors = { ...errors }
		let flag = true;

		if (topic == "") {
			cErrors["topicError"] = true;
			flag = false;
		}
		if (date == "" || date < dateTime) {
            cErrors["dateError"] = true;
            flag = false;
        }
		if (duration == "" || duration == -1) {
			cErrors["durationError"] = true;
			flag = false;
		}
		if (location == "") {
			cErrors["locationError"] = true;
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}


	const topicOptions = [
		{
			topicid: 1,
			topicname: "Some topic"
		},
		{
			topicid: 2,
			topicname: "Some other topic"
		}
	];

	const durationOptions = [
		{ value: -1, label: "Select..."},
		{ value: 10, label: '10 Minutes'},
		{ value: 20, label: '20 Minutes'},
		{ value: 30, label: '30 Minutes'},
		{ value: 45, label: '45 Minutes'},
		{ value: 60, label: '60 Minutes'},
		{ value: 90, label: '90 Minutes'}
	]
		
	return (
		<>
			<CreateMeetingWrapper container>
				<Grid>
					<Grid>
						<HeaderOne>Book a meeting</HeaderOne>
					</Grid>
					<Grid>
						<Grid>
							<FieldWrapper>
								<FormLabel>Meeting Topic</FormLabel>	
								<SelectWrapper>					
									<TextField
										label="Topic"								
										options={topicOptions}
										id="topic"
										error={topicError}
										value={topic}
										onChange={handleChange("topic")}											
										SelectProps={{native: true}}
										helperText={topicError ? "Please choose a topic for the meeting." : ""}
										fullWidth
										required
										select
									>
										{topicOptions.map(option => (
											<option
												key={option.topicid}
												value={option.topicid}
											>
												{option.topicname}
											</option>
										))}
									</TextField>
										
									<FormHelperText></FormHelperText>
								</SelectWrapper>
							</FieldWrapper>
						
							<FieldWrapper>		
								<FormLabel>Time and Date</FormLabel>		
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopTimePicker
										clearable
										id="date"
										error={dateError}
										value={date}	
										helperText={dateError ? "Please choose a valid date/time.": ""}						
										onChange={(newValue) => {
											setDateValue(newValue);
										}}
										minDateTime={new Date()}								
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</FieldWrapper>	
						
							<FieldWrapper>		
								<FormLabel>Duration</FormLabel>		
								<SelectWrapper>			
									<FormControl fullWidth>
										<TextField
											select
											required
											id="duration"
											options={durationOptions}
											value={duration}
											error={durationError}
											SelectProps={{native: true}}
											helperText={durationError ? "Please choose a meeting duration": ""}	
											onChange={handleChange("duration")}
											label="Duration"
										>			
											{durationOptions.map(option => (
												<option
													key={option.value}
													value={option.value}
												>
													{option.label}
												</option>
											))}	
										</TextField>			
										<FormHelperText></FormHelperText>
									</FormControl>
								</SelectWrapper>	
							</FieldWrapper>	

							<FieldWrapper>		
								<FormLabel>Location</FormLabel>
								<TextField
									//margin="normal"
									error={locationError}
									helperText={locationError ? "Please enter a location for the meeting." : ""}
									required
									fullWidth
									name="location"
									label="Location"
									id="location"
									onChange={handleChange("location")}
									//autoComplete="current-password"
								/>
							</FieldWrapper>	
						
							<FieldWrapper>				
								<FormLabel>Meeting Description</FormLabel>
								<TextField
									id="description"
									label="Description"
									name="description"
									multiline
									rows={3}
									fullWidth
									value={description}
									//onChange={setDescriptionValue}
									onChange={handleChange("description")}
								/>
							</FieldWrapper>
						
							{/* Material UI has loadingButton can add later for better UX */}
							<Button
								onClick={handleCreate}
								variant="contained"
								fullWidth
								sx={{ mt: 1, mb: 2, color: "#ffffff"}}
							>
								Create Meeting
							</Button>
						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</>
	)
}

export default Createmeeting;