import React from "react";
import { Button, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import Color from "../../assets/colors";
import { ButtonWrapper, CardStyled, CardTitle, LinearProgressStyled, ProgressBarWrapper, ProgressLabel } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";

function Milestone(props) {
    const { title, tasks } = props;

    const normalise = (value) => ((value - 0) * 100) / (5 - 0);

    const HomeMilestone = (props) => {
        // Something to count the number of completed tasks and uncompleted tasks in a milestone

        return (
            <CardStyled variant="outlined">
                <CardActionArea>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <CardTitle>{title ? title : "Milestone Name"}</CardTitle>
                                <ProgressBarWrapper container>
                                    <Grid item xs={8}>
                                        <LinearProgressStyled variant="determinate" color="secondary" value={50} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ProgressLabel>3/6 tasks</ProgressLabel>
                                    </Grid>
                                </ProgressBarWrapper>
                            </Grid>
                            <ButtonWrapper item xs={2}>
                                <SharedButton buttonType="Action" width="Short">View</SharedButton>
                            </ButtonWrapper>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </CardStyled>
        )
    }

    // Might want to make conditional to render Milestone differently depending on what page we're on
    // May want to create separate files for the different extra components that are present for each different render of Milestone
    return (
        <HomeMilestone />
    )
}

export default Milestone;