import React from "react";
import { Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import Color from "../../assets/colors";
import { ButtonWrapper, CardStyled, CardSubtitle, CardTitle } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";

function Meeting(props) {
    const { title, agenda, date, time, venue, notes } = props;

    const MyMeetingsHome = () => {
        return (
            <CardStyled variant="outlined">
                <CardActionArea>
                    <CardContent>
                        <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <CardTitle>{title ? title : "Meeting Title"}</CardTitle>
                            <CardSubtitle>{(time ? time : "Time") + ", " + (venue ? venue : "Venue")}</CardSubtitle>
                        </Grid>
                        <ButtonWrapper item xs={2}>
                            <SharedButton buttonType="Action" width="Short">View</SharedButton>
                        </ButtonWrapper>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </CardStyled>
        )
    }

    return (
        <MyMeetingsHome />
    )
}

export default Meeting;