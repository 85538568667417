import React, {useState, useEffect} from 'react';
import { Divider, Grid } from "@mui/material";
import Meeting from "./Meeting";
import { Date, DateSessions, MyMeetingsWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';
import { convertDate } from '../../util';

async function getMeetings(token, mentor) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/event/" + (mentor ? "mentor" : "mentee")  + "/mysessions", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return [];
    return json.data;
}

function MyMeetingsHome({ token, mentor }) {

    const [meetings, setMeetings] = useState([]);

    useEffect(() => {
        (async () => {
            let res = await getMeetings(token, mentor);
            if (res != null) setMeetings(res);
        })();
    }, []);

    return (
        <MyMeetingsWidget>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Title>My Meetings</Title>
                </Grid>
                <Grid item xs={2}>
                    <SharedButton buttonType="Action" width="Short">View All</SharedButton>
                </Grid>
            </Grid>
            <DateSessions container>
                <Date>Date</Date>
                <Divider />
                {meetings.map((meeting) => {     
                    return (<Meeting title={meeting.description} time={convertDate(meeting.proposeddate)} venue={meeting.location} key={meeting.sessionid}/>) 
                })}
                <Meeting title={"Test meeting"}/>
                <Meeting title={"Another meeting"}/>
            </DateSessions>
        </MyMeetingsWidget>
    )
}

export default MyMeetingsHome;