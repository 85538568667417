import React, { useState } from 'react';
import Login from './components/login/Login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import useToken from './hooks/useToken';
import MenteeHome from './pages/menteehome';
import MentorHome from './pages/mentorhome';
import Menteecreatemeeting from './pages/menteecreatemeeting';
import Creategroupmeeting from './pages/creategroupmeeting';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import Color from './assets/colors';
import Register from './pages/register';
import MenteeEditProfile from './pages/menteeeditprofile';


const theme = createTheme({
  typography: {
    fontFamily: ["Work Sans", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: Color.blue100,
    },
    secondary: {
      main: Color.green100,
    }
  }
});

function App() {
  const { token, setToken } = useToken();

  // Check if logged in user
  // if (!token) {
  //   return (
  //     <Login setToken={setToken} />
  //   )
  // }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router> 
          <Routes>
            <Route path='/login' element={<Login setToken={setToken} />} />
            <Route path='/register' element={<Register setToken={setToken} />} />

            <Route path='/mentee/home' element={<MenteeHome setToken={setToken} token={token} />} />
            <Route path='/mentor/home' element={<MentorHome setToken={setToken} token={token} />} />
            <Route path='/mentor/createmeeting' element={<Creategroupmeeting />} />
            <Route path='/mentee/createmeeting' element={<Menteecreatemeeting />} />
            <Route path='/mentee/profile/edit' element={<MenteeEditProfile setToken={setToken} token={token} />} />

            <Route path='/mentee' element={<Navigate replace to="/mentee/home" />} />
            <Route path='/mentor' element={<Navigate replace to="/mentor/home" />} />
            <Route path='/menteecreatemeeting' element={<Navigate replace to="/mentee/createmeeting" />} />
            <Route path='/creategroupmeeting' element={<Navigate replace to="/mentor/createmeeting" />} />

            {/* Temporary until mentee profile page made */}
            <Route path='/mentee/profile' element={<Navigate replace to="/mentee/profile/edit" />} /> 

          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;