import React, { useState } from "react";
import {
    StepContainer,
    StepNumber,
    StepNumbersContainer,
    StepNumberWrapper,
    StepperContainer,
    RegistrationContainer,
    ButtonWrapper,
    NextButton,
    BackButton
} from "./styles";
import MentorOrMentee from "../MentorOrMentee/MentorOrMentee";
import BasicInformation from "../BasicInformation/BasicInformation";
import MentorProfile from "../MentorProfile/MentorProfile";
import SharedButton from "../../shared/SharedButton/SharedButton";
import MenteeProfile from "../MenteeProfile/MenteeProfile";

function RegistrationSteps(props) {
    const {
        handleChange,
        handleProfileDescChange,
        handleRoleChange,
        handleBack,
        handleNext,
        handleSubmit,
        values,
        errors,
        activeStep,
    } = props;

    const {
        role,
        firstName,
        lastName,
        businessArea,
        jobTitle,
        profilePhoto,
        profileDescription,
        topics,
        metadata,
    } = values;

    const {
        firstNameError,
        lastNameError,
        businessAreaError,
        jobTitleError,
        topicsError,
    } = errors;

    return (
        <RegistrationContainer>
            <StepperContainer>
                <StepNumbersContainer>
                    <StepContainer step="1" activeStep={activeStep}>
                        <StepNumberWrapper step="1" activeStep={activeStep}>
                            <StepNumber>1</StepNumber>
                        </StepNumberWrapper>
                    </StepContainer>

                    <StepContainer step="2" activeStep={activeStep}>
                        <StepNumberWrapper step="2" activeStep={activeStep}>
                            <StepNumber>2</StepNumber>
                        </StepNumberWrapper>
                    </StepContainer>

                    <StepContainer step="3" activeStep={activeStep}>
                        <StepNumberWrapper step="3" activeStep={activeStep}>
                            <StepNumber>3</StepNumber>
                        </StepNumberWrapper>
                    </StepContainer>
                </StepNumbersContainer>
            </StepperContainer>
            <form>
                {activeStep == 1 ? (
                    <MentorOrMentee
                        handleRoleChange={handleRoleChange}
                        values={values}
                    />
                ) : activeStep == 2 ? (
                    <BasicInformation
                        handleChange={handleChange}
                        handleProfileDescChange={handleProfileDescChange}
                        values={values}
                        errors={errors}
                    />
                ) : role === "mentor" ? (
                    <MentorProfile
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                    />
                ) : (
                    <MenteeProfile
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                    />
                )
                }
                <ButtonWrapper container>
                    <BackButton item xs={6}>
                        <SharedButton
                            buttonType="Error"
                            width="Short"
                            onClick={handleBack}
                        >
                            Back
                        </SharedButton>
                    </BackButton>
                    <NextButton item xs={6}>
                        {activeStep == 3 ? (
                            <SharedButton
                                buttonType="Action"
                                width="Short"
                                onClick={handleSubmit}
                            >
                                Submit
                            </SharedButton>
                        ) : (
                            <SharedButton
                                type="submit"
                                buttonType="Action"
                                width="Short"
                                onClick={handleNext}
                                disabled={role == undefined || role == "" || role == null}
                            >
                                Next
                            </SharedButton>

                        )

                        }
                    </NextButton>
                </ButtonWrapper>
            </form>
        </RegistrationContainer>
    )
}

export default RegistrationSteps;