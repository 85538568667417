import React from "react";
import { Avatar, Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import Color from "../../assets/colors";
import { AvatarContainer, ButtonWrapper, CardStyled, CardSubtitle, CardTextContainer, CardTitle } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";

function Mentee(props) {
    const { name, area, profile } = props;

    return (
        <CardStyled variant="outlined">
            <CardActionArea>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Grid container>
                                <AvatarContainer item xs={2}>
                                    <Avatar sx={{ width: 50, height: 50}} alt="Profile picture" src={profile}>R</Avatar>
                                </AvatarContainer>
                                <CardTextContainer item xs={8}>
                                    <CardTitle>{name ? name : "Name"}</CardTitle>
                                    <CardSubtitle>{area ? area : "Area of Interest"}</CardSubtitle>
                                </CardTextContainer>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonWrapper item xs={2}>
                                <SharedButton buttonType="Action" width="Short">View</SharedButton>
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </CardStyled>
    )
}

export default Mentee;