import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
	BoxStyled,
	ButtonWrapper,
	Caption,
	FieldWrapper,
	FormLabel,
	FormWrapper,
	HeaderOne,
	LinkStyled,
	LinksWrapper,
	RegisterContainer,
	RegisterWrapper,
} from "./styles";
import SharedButton from '../../shared/SharedButton/SharedButton';
import { baseURL } from '../../../config/config';
import RegistrationSteps from '../RegistrationSteps/RegistrationSteps';

// Call API to register user and retrieve token
async function registerUser(params) {
	let data = {
		email: params.email,
		password: params.password,
		firstname: params.firstName,
		secondname: params.lastName,
		businessarea: params.businessArea,
		jobtitle: params.jobTitle,
		profilepicture: params.profilePicture ?? ""
	};

	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	};

	return fetch(baseURL + "/auth/register", request)
		.then(res => res.json()).then(res => {
			if (res.responseCode !== 200) {
				alert("Registration failed. Message: " + res.message);
				return false;
			}
			return true;
		});
}

function RegisterMain({ setToken }) {
	const [values, setValues] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		role: "",
		firstName: "",
		lastName: "",
		businessArea: "",
		jobTitle: "",
		profilePhoto: "",
		profileDescription: "",
		topics: [],
		metadata: "",
	});

	const {
		email,
		password,
		confirmPassword,
		role,
		firstName,
		lastName,
		businessArea,
		jobTitle,
		profilePhoto,
		profileDescription,
		topics,
		metadata,
	} = values;

	const [errors, setErrors] = useState({
		emailError: false,
		passwordError: false,
		confirmPasswordError: false,
		firstNameError: false,
		lastNameError: false,
		businessAreaError: false,
		jobTitleError: false,
		topicsError: false,
	});

	const {
		emailError,
		passwordError,
		confirmPasswordError,
		firstNameError,
		lastNameError,
		businessAreaError,
		jobTitleError,
		topicsError,
	} = errors;

	const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");
	const [activeStep, setActiveStep] = useState(0);

	// Store email and password in state
	const handleChange = (name) => (e, value) => {
		let cValues = { ...values };
		let cErrors = { ...errors }

		if (name == "email" && errors["emailError"]) {
			cErrors["emailError"] = false;
		}
		if (name == "password" && errors["passwordError"]) {
			cErrors["passwordError"] = false;
		}
		if (name == "confirmPassword" && errors["confirmPasswordError"]) {
			cErrors["confirmPasswordError"] = false;
		}
		if (name == "firstName" && errors["firstNameError"]) {
			cErrors["firstNameError"] = false;
		}
		if (name == "lastName" && errors["lastNameError"]) {
			cErrors["lastNameError"] = false;
		}
		if (name == "businessArea" && errors["businessAreaError"]) {
			cErrors["businessAreaError"] = false;
		}
		if (name == "jobTitle" && errors["jobTitleError"]) {
			cErrors["jobTitleError"] = false;
		}

		cValues[name] = e.target.value;
		setValues(cValues);
		setErrors(cErrors);
	};

	const handleProfileDescChange = (desc) => {
		const length = desc.length;
		if (length > 150) {
			desc = desc.substring(0, 150);
		}

		let cValues = { ...values };
		cValues["profileDescription"] = desc;
		setValues(cValues);
	}

	const handleRoleChange = (role) => {
		if (
			role == null ||
			role == undefined ||
			role == ""
		) {
			return;
		}

		let cValues = { ...values };
		cValues["role"] = role;
		setValues(cValues);
	}

	const handleNext = (e) => {
		if (e) {
			e.preventDefault();
		}

		if (activeStep == 2) {
			if (!validateBasicInfoParams()) {
				return;
			}
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		console.log(activeStep);
	};

	const handleBack = (e) => {
		if (e) {
			e.preventDefault();
		}

		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// When register button clicked
	const handleRegister = (e) => {
		if (!validateEmailAndPassword()) {
			return;
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}

	const handleSubmit = async e => {
		e.preventDefault();

		const success = await registerUser(values);
		if (success) {
			window.location.href = "/login?r=1";
		}
	}

	const validateEmailAndPassword = () => {
		let cErrors = { ...errors }
		let flag = true;

		if (email == "") {
			cErrors["emailError"] = true;
			flag = false;
		}
		if (password == "") {
			cErrors["passwordError"] = true;
			flag = false;
		}
		if (confirmPassword == "") {
			cErrors["confirmPasswordError"] = true;
			setConfirmPasswordMessage("Please confirm your password")
			flag = false;
		}
		if (password != confirmPassword) {
			cErrors["confirmPasswordError"] = true;
			setConfirmPasswordMessage("Passwords do not match")
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}

	const validateBasicInfoParams = () => {
		let cErrors = { ...errors };
		let flag = true;

		if (firstName == "") {
			cErrors["firstNameError"] = true;
			flag = false;
		}
		if (lastName == "") {
			cErrors["lastNameError"] = true;
			flag = false;
		}
		if (businessArea == "") {
			cErrors["businessAreaError"] = true;
			flag = false;
		}
		if (jobTitle == "") {
			cErrors["jobTitleError"] = true;
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}

	return (
		<React.Fragment>
			{activeStep > 0 ? (
				<RegistrationSteps
					handleChange={handleChange}
					handleProfileDescChange={handleProfileDescChange}
					handleRoleChange={handleRoleChange}
					handleBack={handleBack}
					handleNext={handleNext}
					handleSubmit={handleSubmit}
					values={values}
					errors={errors}
					activeStep={activeStep}
					metadata={metadata}
				/>
			) : (
				<RegisterContainer container>
					<Grid item xs={6}>
						<FormWrapper container>
							<Grid item xs={8}>
								<HeaderOne>
									Register
								</HeaderOne>
							</Grid>
							<Grid item xs={8}>
								<form>
									<Grid>
										<FieldWrapper>
											<FormLabel>Email</FormLabel>
											<TextField
												error={emailError}
												helperText={emailError ? "Please enter your email" : ""}
												value={email}
												required
												fullWidth
												id="email"
												label="Email"
												name="email"
												autoComplete="email"
												onChange={handleChange("email")}
												autoFocus
											/>
										</FieldWrapper>
										<FieldWrapper>
											<FormLabel>Password</FormLabel>
											<TextField
												error={passwordError}
												helperText={passwordError ? "Please enter a password" : ""}
												value={password}
												label="Password"
												type="password"
												id="password"
												required
												fullWidth
												onChange={handleChange("password")}
											/>
										</FieldWrapper>
										<FieldWrapper>
											<FormLabel>Confirm Password</FormLabel>
											<TextField
												error={confirmPasswordError}
												value={confirmPassword}
												helperText={confirmPasswordError ? confirmPasswordMessage : ""}
												label="Password"
												type="password"
												id="password"
												onChange={handleChange("confirmPassword")}
												required
												fullWidth
											/>
										</FieldWrapper>
										<ButtonWrapper>
											<SharedButton
												type="submit"
												onClick={handleRegister}
												buttonType="Action"
												width="Long"
											>
												Register
											</SharedButton>
										</ButtonWrapper>
										<LinksWrapper container>
											<RegisterWrapper>
												<Caption>Already have an account? </Caption>
												<LinkStyled href="/login" variant="body2">
													Log in.
												</LinkStyled>
											</RegisterWrapper>
										</LinksWrapper>
									</Grid>
								</form>
							</Grid>
						</FormWrapper>
					</Grid>
					<Grid item xs={6}>
						<BoxStyled />
					</Grid>
				</RegisterContainer>
			)
			}
		</React.Fragment>
	)
}

RegisterMain.propTypes = {
	setToken: PropTypes.func.isRequired
}

export default RegisterMain;
