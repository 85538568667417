import { Grid } from '@mui/material';
import { Navigate } from 'react-router-dom';
import React from 'react';
import CreateGroupSessionWidget from '../../components/groupsessions/widgets/CreateGroupSession/CreateGroupSessionWidget';
import Login from '../../components/login/Login';
import MyMeetingsHome from '../../components/meetings/MyMeetingsHome';
import MenteeList from '../../components/mentees/MenteeList';
import SharedDrawerMentor from '../../components/shared/SharedDrawerMentor/SharedDrawerMentor';
import { Title, TitleWrapper } from './styles';

function MentorHome({ setToken, token }) {
    if (!token) {
        return <Navigate to='/login' />
    }

    return (
        <Grid container>
            <TitleWrapper container>
                <SharedDrawerMentor token={token} />
                <Grid item xs={8}>
                    <Title>Dashboard</Title>
                </Grid>
            </TitleWrapper>
            <MenteeList token={token} />
            <MyMeetingsHome token={token} mentor={true} />
            <CreateGroupSessionWidget />
        </Grid>
    );
}

export default MentorHome;