import React from "react";
import { Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import SharedButton from "../../../shared/SharedButton/SharedButton";
import { ButtonWrapper, CardStyled, CardSubtitle, CardTitle, Date, DateSessions, GroupSessionsWidget, Title } from "./styles";

function GroupSession(props) {
    const { title, description, leader, topic, date, time, venue } = props;

    const GroupSessionHomeWidget = () => {
        return (
            <CardStyled variant="outlined">
                <CardActionArea>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <CardTitle>{title ? title : "Title"}</CardTitle>
                                <CardSubtitle>{(time ? time : "Time") + ", " + (venue ? venue : "Venue")}</CardSubtitle>
                            </Grid>
                            <ButtonWrapper item xs={2}>
                                <SharedButton buttonType="Action" width="Short">View</SharedButton>
                            </ButtonWrapper>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </CardStyled>
        )
    }

    return (
        <GroupSessionsWidget>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Title>Group Sessions Available</Title>
                </Grid>
                <Grid item xs={2}>
                    <SharedButton buttonType="Action" width="Short">view all</SharedButton>
                </Grid>
            </Grid>
            <DateSessions container>
                <Date>Date</Date>
                <Divider />
                <GroupSessionHomeWidget />
                <GroupSessionHomeWidget />
            </DateSessions>
        </GroupSessionsWidget>
    )
}

export default GroupSession;