import React from "react";
import { BodyText, ButtonWrapper, CreateGroupSessionContainer, Subtitle, TextWrapper, Title } from "./styles";
import SharedButton from "../../../shared/SharedButton/SharedButton";

function CreateGroupSessionWidget(props) {
    const { area } = props;

    return (
        <CreateGroupSessionContainer>
                <TextWrapper>
                    <Title>Create a Group Session</Title>
                    <Subtitle>Topic areas of experience:</Subtitle>
                    <BodyText>{area ? area : "Leadership, Communication"}</BodyText>
                </TextWrapper>
                <ButtonWrapper>
                    <SharedButton buttonType="Action" width="Short">Create</SharedButton>
                </ButtonWrapper>
        </CreateGroupSessionContainer>
    )
}

export default CreateGroupSessionWidget;