import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';
import EditMenteeProfile from '../../components/editmenteeprofile/EditMenteeProfile';
import { baseURL } from '../../config/config';

function MenteeEditProfile({ setToken, token }) {
    return token ? (
        <Grid container>
            <TitleWrapper container>
                <SharedDrawerMentee token={token} />
                <Grid item xs={8}>
                    <Title>Mentee profile</Title>
                </Grid>
            </TitleWrapper>
            <EditMenteeProfile />
        </Grid>
    ) : (
        <Login setToken={setToken} />
    );
}

export default MenteeEditProfile;