import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { Grid, Link, Typography } from '@mui/material';
import Color from '../../assets/colors';
import { Box } from '@mui/system';

// Typography
export const HeaderOne = styled(Typography)`
    && {
        font-size: ${FontSize.header1};
        font-weight: 700;
    }
`;

export const FormLabel = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
    }
`;

// Grid
export const CreateMeetingWrapper = styled(Grid)`
    && {
        height: 100vh;
        margin: 0 0 0 20px;
        padding: 0;
    }
`;

export const FormWrapper = styled(Grid)`
    && {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 15vh 20px;
    }
`;

export const FieldWrapper = styled(Grid)`
    && {
        margin: 18px 0 18px 0;
        font-family: "Calibri";
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const SelectWrapper = styled(Grid)`
    && {
        font-size: 20px;

    }
`


