import { Grid } from '@mui/material';
import React from 'react';
import GroupSession from '../../components/groupsessions/widgets/GroupSessionList/GroupSession';
import MyMeetingsHome from '../../components/meetings/MyMeetingsHome';
import MyMentor from '../../components/mentor/MyMentor';
import PlanOfAction from '../../components/planofaction/PlanOfActionHome';
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';
import { Navigate } from 'react-router-dom';

function MenteeHome({ setToken, token }) {
    if (!token) {
        return <Navigate to='/login' />
    }

    return (
        <Grid container>
            <TitleWrapper container>
                <SharedDrawerMentee token={token} />
                <Grid item xs={8}>
                    <Title>Dashboard</Title>
                </Grid>
                <Grid item xs={2}>
                    <SharedButton buttonType="Action" width="Long">Find Mentor</SharedButton>
                </Grid>
            </TitleWrapper>
            <MyMentor token={token} />
            <GroupSession />
            <PlanOfAction />
            <MyMeetingsHome token={token} mentor={false} />
        </Grid>
    );
}

export default MenteeHome;