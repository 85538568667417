import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { 
    RegistrationContainer, 
    TitleContainer, 
    InputContainer,
    Title,
    Label 
} from "./styles";

function MentorProfile(props) {
    const {
        handleChange,
        values,
        errors
    } = props;

    const {
        topics,
    } = values;

    const {
        topicsError,
    } = errors;

    return (
        <RegistrationContainer>
            <TitleContainer>
                <Title>Mentor Profile</Title>
            </TitleContainer>
            <InputContainer>
                    <Label>Area of Expertise</Label>
                    <TextField
                        label="Area of Expertise"
                        id="area-of-expertise"
                        error={topicsError}
                        value={topics}
                        onChange={handleChange("topics")}
                        helperText={topicsError ? "Please choose the area you wish to mentor others in" : ""}
                        fullWidth
                        required
                        select
                    >
                        <MenuItem value="Marketing">Marketing</MenuItem>
                        <MenuItem value="Finance">Finance</MenuItem>
                        <MenuItem value="Operations Management">Operations Management</MenuItem>
                        <MenuItem value="Human Resource">Human Resource</MenuItem>
                        <MenuItem value="IT">IT</MenuItem>
                    </TextField>
                </InputContainer>
        </RegistrationContainer>
    )
}

export default MentorProfile;