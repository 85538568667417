import React from "react";
import { Button, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import Color from "../../assets/colors";
import Milestone from "./Milestone";
import { PlanOfActionWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";

function PlanOfAction(props) {
    const { title, tasks } = props;

    // Might want to make conditional to render Milestone differently depending on what page we're on
    // May want to create separate files for the different extra components that are present for each different render of Milestone
    return (
        <PlanOfActionWidget>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Title>Plan of Action</Title>
                </Grid>
                <Grid item xs={2}>
                    <SharedButton buttonType="Action" width="Short">View All</SharedButton>
                </Grid>
            </Grid>
            <Grid container>
                <Milestone />
                <Milestone />
            </Grid>
        </PlanOfActionWidget>
    )
}

export default PlanOfAction;