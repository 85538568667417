import React from 'react';
import Creategroupmeeting from '../../components/creategroupmeeting/Creategroupmeeting';

function CreateGroupMeeting() {
    return (
        <>
            <Creategroupmeeting></Creategroupmeeting>
        </>
    )
}

export default CreateGroupMeeting;
