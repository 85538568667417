import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { baseURL } from '../../config/config';
import {
	BoxStyled,
	ButtonWrapper,
	Caption,
	ErrorText,
	FieldWrapper,
	ForgotPasswordWrapper,
	FormLabel,
	FormWrapper,
	HeaderOne,
	LinkStyled,
	LinksWrapper,
	LogInWrapper,
	RegisterWrapper,
} from "./styles";
import SharedButton from '../shared/SharedButton/SharedButton';
import { Navigate } from 'react-router-dom';

// Call API to log user in and retrieve token
async function loginUser(params) {
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(params)
	};

	return fetch(baseURL + "/auth/login", request)
		.then(res => res.json());
}

function Login({ setToken }) {
	const [values, setValues] = useState({
		email: "",
		password: "",
	});
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [emailHelperText, setEmailHelperText] = useState("");
	const [passwordHelperText, setPasswordErrorText] = useState("");
	const [showErrorText, setShowErrorText] = useState(false);
	const [errorText, setErrorText] = useState(false);

	const { email, password } = values;

	// Store email and password in state
	const handleChange = (name) => (e) => {
		setValues({ ...values, [name]: e.target.value });
	};

	// Call loginUser when log in button clicked
	const handleSubmit = async e => {
		e.preventDefault();

		if (email === "") {
			setEmailError(true);
			setEmailHelperText("Please enter your email.");
		} else {
			setEmailError(false);
			setEmailHelperText("");
		}

		if (password === "") {
			setPasswordError(true);
			setPasswordErrorText("Please enter your password.");
		} else {
			setPasswordError(false);
			setPasswordErrorText("");
		}

		// Check whether email and password is empty
		if (email === "" || password === "") {
			return;
		}

		let params = {
			email: email,
			password: password,
		}

		setShowErrorText(false);

		await loginUser(params)
			.then((res) => {
				console.log(res);
				if (res.responseCode === 200) {
					setToken(res.data);
					window.location.href = "/mentee/home";
				}
				else {
					setShowErrorText(true);
					setErrorText("Incorrect login details.");
					return;
				}
			});
	}

	return (
		<>
			<LogInWrapper container>

				<Grid item xs={6}>
					<form>
						<FormWrapper container>
							<Grid item xs={8}>
								<HeaderOne>
									Log In
								</HeaderOne>
								<ErrorText
									show={showErrorText}
								>
									{errorText}
								</ErrorText>
							</Grid>
							<Grid item xs={8}>
								<Grid>
									<FieldWrapper>
										<FormLabel>Email</FormLabel>
										<TextField
											error={emailError}
											helperText={emailHelperText}
											margin="normal"
											required
											fullWidth
											id="email"
											label="Email"
											name="email"
											autoComplete="email"
											onChange={handleChange("email")}
											autoFocus
										/>
									</FieldWrapper>
									<FieldWrapper>
										<FormLabel>Password</FormLabel>
										<TextField
											error={passwordError}
											helperText={passwordHelperText}
											margin="normal"
											required
											fullWidth
											name="password"
											label="Password"
											type="password"
											id="password"
											autoComplete="current-password"
											onChange={handleChange("password")}
										/>
									</FieldWrapper>
									<ButtonWrapper>
										<SharedButton
											type="submit"
											onClick={handleSubmit}
											buttonType="Action"
											width="Long"
										>
											Log In
										</SharedButton>
									</ButtonWrapper>
									<LinksWrapper container>
										<RegisterWrapper>
											<Caption>New to the site? </Caption>
											<LinkStyled href="/register" variant="body2">
												Register.
											</LinkStyled>
										</RegisterWrapper>
										<ForgotPasswordWrapper>
											<Caption>Forgotten your password? </Caption>
											<LinkStyled href="#" variant="body2">
												{"Click here."}
											</LinkStyled>
										</ForgotPasswordWrapper>
									</LinksWrapper>
								</Grid>
							</Grid>
						</FormWrapper>
					</form>
				</Grid>
				<Grid item xs={6}>
					<BoxStyled />
				</Grid>
			</LogInWrapper>
		</>
	)
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired
}

export default Login;