export const convertDate = function (date) {
    let d = new window.Date(date);
    let day = d.getDay().toString().padStart(2, '0');
    let month = d.getMonth().toString().padStart(2, '0');
    let year = d.getFullYear().toString().slice(-2);
    let hour = d.getHours().toString().padStart(2, '0');
    let minute = d.getMinutes().toString().padStart(2, '0');

    let string = `${day}/${month}/${year} ${hour}:${minute}`;
    return string;
};