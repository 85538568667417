import React, {useState, useEffect} from 'react';
import { Avatar, Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import Color from "../../assets/colors";
import Mentee from "./Mentee";
import { MyMenteesWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';

async function getMentees(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/matching/mymentees", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return [];
    return json.data;
}

function MenteeList({ token }) {

    const [mentees, setMentees] = useState([]);

    useEffect(() => {
        (async () => {
            let mentees = await getMentees(token);
            if (mentees != null) setMentees(mentees);
        })();
    }, []);

    return (
        <MyMenteesWidget>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Title>My Mentees</Title>
                </Grid>
                <Grid item xs={2}>
                    <SharedButton buttonType="Action" width="Short">View All</SharedButton>
                </Grid>
            </Grid>
            <Grid container>
                {mentees.map((mentee) => {     
                    return (<Mentee name={mentee.firstname + " " + mentee.secondname} area={mentee.topicname} profile={mentee.profilepicture} key={mentee.userid} />) 
                })}
                <Mentee name="Mentee 1" area="Testing Department"/>
                <Mentee name="Mentee 2" area="Testing Department"/>
            </Grid>
        </MyMenteesWidget>
    )
}

export default MenteeList;