import React from 'react';
import Menteecreatemeeting from '../../components/menteecreatemeeting/Menteecreatemeeting';
import SharedSidebar from '../../components/shared/SharedSidebar/SharedSidebar'

function MenteeCreateMeeting() {
    return (
        <>
            <SharedSidebar></SharedSidebar>
            <Menteecreatemeeting></Menteecreatemeeting>
        </>
    )
}

export default MenteeCreateMeeting;
