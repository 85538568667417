import { MenuItem, Slider, TextField } from "@mui/material";
import React from "react";
import {
    RegistrationContainer,
    TitleContainer,
    InputContainer,
    Title,
    Label,
    SubLabel,
    SkillRatingContainer
} from "./styles";

function MenteeProfile(props) {
    const {
        handleChange,
        values,
        errors
    } = props;

    const {
        topics,
    } = values;

    const {
        topicsError,
    } = errors;

    const proficiencies = [
        "Time Management",
        "Leadership",
        "Organisation",
        "Project Management",
    ]

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        }
    ]

    const skillRatings = proficiencies.map((proficiency) => {
        return (
            <SkillRatingContainer>
                <SubLabel>How would you rate your skills in {proficiency}?</SubLabel>
                <Slider
                    aria-label={proficiency}
                    defaultValue={3}
                    step={1}
                    marks={marks}
                    valueLabelDisplay="auto"
                    min={1}
                    max={5}
                />
            </SkillRatingContainer>
        )
    }
    );

    return (
        <RegistrationContainer>
            <TitleContainer>
                <Title>Mentee Profile</Title>
            </TitleContainer>
            <InputContainer>
                <Label>Area of Interest</Label>
                <TextField
                    label="Area of Interest"
                    id="area-of-interest"
                    error={topicsError}
                    value={topics}
                    onChange={handleChange("topics")}
                    helperText={topicsError ? "Please choose the area you are interested in being mentored in" : ""}
                    fullWidth
                    required
                    select
                >
                    <MenuItem value="Marketing">Marketing</MenuItem>
                    <MenuItem value="Finance">Finance</MenuItem>
                    <MenuItem value="Operations Management">Operations Management</MenuItem>
                    <MenuItem value="Human Resource">Human Resource</MenuItem>
                    <MenuItem value="IT">IT</MenuItem>
                </TextField>
            </InputContainer>
            <InputContainer>
                <Label>Proficiencies</Label>
                {skillRatings}
            </InputContainer>
        </RegistrationContainer>

    )
}

export default MenteeProfile;