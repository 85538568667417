import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { CardActionArea, CardContent } from '@mui/material';
import SharedButton from '../../shared/SharedButton/SharedButton';
import {
    ButtonWrapper,
    CardContentStyled,
    CardMediaStyled,
    CardMediaWrapper,
    CardsContainer,
    CardStyled,
    CardTitle,
    CardWrapper,
    MentorOrMenteeWrapper,
    Title,
    TitleWrapper
} from './styles';
import mentorImageUrl from '../../../static/images/mentor.jpg';
import menteeImageUrl from '../../../static/images/mentee.jpg';
import { Subtitle } from '../../groupsessions/widgets/GroupSessionList/styles';

function MentorOrMentee(props) {
    const {
        handleRoleChange,
    } = props;

    const [isSelected, setIsSelected] = useState("");

    const handleClick = (key) => (e) => {
        setIsSelected(key);
        handleRoleChange(key);
    }

    return (
        <MentorOrMenteeWrapper>
            <TitleWrapper>
                <Title>Would you like to register as a mentor or a mentee?</Title>
            </TitleWrapper>
            <TitleWrapper>
                <Subtitle>You can create another profile later on if you wish to be both.</Subtitle>
            </TitleWrapper>
            <CardsContainer container spacing={2}>
                <CardWrapper item xs={5}>
                    <CardStyled
                        key={"mentor"}
                        selected={isSelected === "mentor"}
                        onClick={handleClick("mentor")}
                    >
                        <CardActionArea>
                            <CardMediaWrapper>
                                <CardMediaStyled
                                    component="img"
                                    height="300"
                                    image={mentorImageUrl}
                                    alt="teacher"
                                />
                            </CardMediaWrapper>
                            <CardContentStyled>
                                <CardTitle>Mentor</CardTitle>
                            </CardContentStyled>
                        </CardActionArea>
                    </CardStyled>
                </CardWrapper>
                <CardWrapper item xs={5}>
                    <CardStyled
                        key={"mentee"}
                        selected={isSelected === "mentee"}
                        onClick={handleClick("mentee")}
                    >
                        <CardActionArea>
                            <CardMediaWrapper>
                                <CardMediaStyled
                                    component="img"
                                    height="300"
                                    image={menteeImageUrl}
                                    alt="teacher"
                                />
                            </CardMediaWrapper>
                            <CardContentStyled>
                                <CardTitle>Mentee</CardTitle>
                            </CardContentStyled>
                        </CardActionArea>
                    </CardStyled>
                </CardWrapper>
            </CardsContainer>
        </MentorOrMenteeWrapper>
    )
}

export default MentorOrMentee;